// firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';

// const firebaseConfig = {
//   apiKey: "AIzaSyBT4ipIozYVQSkujs_MtqnBg0JtOyD07bU",
//   authDomain: "YOUR_AUTH_DOMAIN",
//   projectId: "YOUR_PROJECT_ID",
//   storageBucket: "YOUR_STORAGE_BUCKET",
//   messagingSenderId: "YOUR_MESSAGING_SENDER_ID",
//   appId: "YOUR_APP_ID"
// };
const firebaseConfig = {
    apiKey: "AIzaSyBT4ipIozYVQSkujs_MtqnBg0JtOyD07bU",
    authDomain: "skriya-909d2.firebaseapp.com",
    projectId: "skriya-909d2",
    storageBucket: "skriya-909d2.firebasestorage.app",
    messagingSenderId: "757107375455",
    appId: "1:757107375455:web:5ffd213cfa2dd1ba734aea",
    measurementId: "G-H5V8N8F1EF"
  };
// const firebaseConfig = {
//     apiKey: "AIzaSyBl9s6Qaikb6Y-hYz8Kbc4u85axJOcfEBs",
//     authDomain: "new-project-3ec84.firebaseapp.com",
//     projectId: "new-project-3ec84",
//     storageBucket: "new-project-3ec84.firebasestorage.app",
//     messagingSenderId: "544259078133",
//     appId: "1:544259078133:web:b58053484c8906e9e6d2bc",
//     measurementId: "G-DX35DR2FMB"
//   };
  
  

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
