
import AboutUs from './AboutUs';
import Categories from './Categories';
import ComingSoon from './comingsoon';
import FAQsAccordion from './faqs';
import Header from './Header';
import Slider from './Slider';
import VisitorCounter from './visitorCount';

function App() {
  return (
    <div className="App">
      <Header />
      <Slider />
      {/* <div>
        <div className='bg-green-600 p-6'>
            <p className='text-white text-2xl font-bold text-center'>Coming Soon At Your Place</p>
        </div>
      </div> */}
      <ComingSoon />
      <div className='mt-3'>
      <Categories />
      </div>
      <AboutUs />
      <FAQsAccordion />
      <div className="max-w-7xl mx-auto  p-6 text-center">
        <p className="text-lg text-gray-800 font-semibold">Thank you for choosing <span className="text-green-500">Skriya</span> — your partner in growth!</p>
      </div>
      <VisitorCounter />
    </div>
  );
}

export default App;
