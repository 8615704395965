import React from "react";

const AboutUs = () => {
  return (
    <div className="max-w-7xl mx-auto  p-6 ">
      <h1 className="text-2xl font-bold text-green-600 text-center mb-4">About Us</h1>

      <div className="text-lg text-gray-700 space-y-4">
        <p>
          Welcome to <span className="font-semibold text-green-500">Skriya</span>, your trusted partner in agricultural solutions. We are a dedicated service provider specializing in the delivery of premium fertilizers, high-quality cattle feed, effective pesticides, and top-grade seeds — all straight to your doorstep.
        </p>
        
        <p>
          Our mission is to empower farmers, gardeners, and agricultural businesses by providing easy access to essential products that help grow healthy crops, nourish livestock, and ensure sustainable farming practices. With Skriya, you can count on a reliable, fast, and efficient delivery service that brings everything you need right to your door.
        </p>

        <p>
          Whether you're managing a small garden, running a farm, or overseeing a commercial agricultural operation, we offer products tailored to meet your unique needs. We believe in quality and sustainability, carefully selecting our products from trusted manufacturers and suppliers to help you achieve the best results.
        </p>

        <div className="space-y-4 mt-6">
          <h2 className="text-xl font-semibold text-gray-800">Why Choose Skriya?</h2>
          <ul className="list-disc pl-6 space-y-2 text-gray-600">
            <li><strong>Wide Range of Products:</strong> A comprehensive selection of fertilizers, seeds, pesticides, and cattle feed, designed to meet the diverse needs of the agricultural community.</li>
            <li><strong>Fast & Reliable Delivery:</strong> Count on us for prompt and efficient delivery straight to your door, whenever you need it.</li>
            <li><strong>Customer-Centric Approach:</strong> We prioritize your satisfaction, with a friendly support team always ready to assist with any queries.</li>
            <li><strong>Quality & Sustainability:</strong> We provide products that not only enhance productivity but also support sustainable farming practices.</li>
          </ul>
        </div>

        <div className="mt-6 text-center">
          <p className="text-center text-gray-800 text-xl font-bold mb-4">Our Service Launches Soon!</p>
          <p className="text-md text-gray-600 mt-2">
            We’re excited to announce that Skriya will soon be delivering top-quality agricultural products straight to your doorstep. Stay tuned as we prepare to launch our service and help you grow better, faster, and smarter.
          </p>
        </div>
      </div>

      
    </div>
  );
};

export default AboutUs;
