import React from "react";

const Header = () => {
    return (
        <>
            <header className="sticky top-0 z-50 p-1 sm:p-2 shadow-md bg-white text-center">
                {/* Main Logo Text */}
                <h1 className="text-2xl sm:text-5xl font-bold text-green-600">
                    SKRIYA
                </h1>

                {/* Tagline Text */}
                <p className="text-xs sm:text-sm text-[#964B00] font-medium mt-0.5">
                    On your land, on your side – supporting farmers everywhere
                </p>
            </header>
            <hr className="border-gray-300" />
        </>
    );
};

export default Header;
