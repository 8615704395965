import React, { useState } from "react";

const FAQsAccordion = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const faqs = [
        {
            question: "What is SKRIYA?",
            answer: "SKRIYA is a dedicated platform committed to supporting farmers and agricultural professionals by delivering high-quality agricultural products directly to your doorstep. Our mission is to empower farmers with the essential resources they need to enhance productivity and sustainability."
        },
        {
            question: "What products do you offer?",
            answer: "We offer fertilizers, cattle feeds, pesticides, and seeds."
        },
        // {
        //     question: "Will there be special promotions at launch?",
        //     answer: "Yes! We are planning special promotions and discounts for our launch. Be sure to subscribe to our newsletter for exclusive access."
        // },
        {
            question: "What products will be available on SKRIYA?",
            answer: "Our website will feature a variety of products, including fertilizers, cattle feeds, pesticides, and seeds, all designed to support farmers everywhere."
        },
        {
            question: "Can I contact you for more information?",
            answer: "Absolutely! Feel free to reach out to us via email at support@skriya.com We’re here to help and would love to hear from you!"
        },
        {
            question: "Will there be a customer support service once the website is live?",
            answer: "Yes, we will have a dedicated customer support team available to assist you with any inquiries or issues once our website is live."
        }
        // Add more FAQs as needed
    ];

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="max-w-7xl mx-auto  p-6  ">
            <h2 className="text-2xl font-bold text-green-600 text-center mb-4">Frequently Asked Questions</h2>
            <div className="space-y-2">
                {faqs.map((faq, index) => (
                    <div key={index}>
                        <button
                            onClick={() => toggleAccordion(index)}
                            className="flex justify-between w-full p-4 text-left border rounded-md shadow-md focus:outline-none"
                        >
                            <span className="font-semibold">{faq.question}</span>
                            <span>{activeIndex === index ? '-' : '+'}</span>
                        </button>
                        {activeIndex === index && (
                            <div className="p-4 border-l-4 border-green-600 bg-green-50 rounded-md">
                                <p>{faq.answer}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQsAccordion; // Make sure to export the component
