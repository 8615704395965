import { db } from './firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';

export const incrementVisitorCount = async () => {
  const countRef = doc(db, "visitorCount", "count");

  try {
    const countSnap = await getDoc(countRef);

    if (countSnap.exists()) {
      await updateDoc(countRef, {
        count: countSnap.data().count + 1,
      });
    } else {
      await setDoc(countRef, { count: 1 });
    }
  } catch (error) {
    console.error("Error updating visitor count:", error);
  }
};

export const getVisitorCount = async () => {
  const countRef = doc(db, "visitorCount", "count");
  const countSnap = await getDoc(countRef);
  return countSnap.exists() ? countSnap.data().count : 0;
};
