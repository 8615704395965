import React, { useEffect, useState } from 'react';
import { incrementVisitorCount, getVisitorCount } from './visitorCountService';

const VisitorCounter = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const updateVisitorCount = async () => {
      const hasVisited = localStorage.getItem("hasVisited");

      // If user hasn't visited before, increment the count
      if (!hasVisited) {
        await incrementVisitorCount();
        localStorage.setItem("hasVisited", "true");
      }

      // Fetch the latest visitor count from Firestore
      const currentCount = await getVisitorCount();
      setCount(currentCount);
    };

    updateVisitorCount();
  }, []);

  return (
    <div className='max-w-7xl mx-auto text-right'>
      <h2>Total Visitors: {count}</h2>
    </div>
  );
};

export default VisitorCounter;
