import React from "react";

const ComingSoon = () => {
  return (
    <div className="relative group overflow-hidden">
      {/* Container with gradient background and interactive hover effects */}
      <div className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 p-8 shadow-lg  transform transition duration-500 ease-in-out group-hover:scale-105 group-hover:shadow-2xl overflow-hidden relative">
        {/* Text with sliding and bounce effect */}
        <p className="text-white text-4xl font-bold text-center animate-text-slide animate-bounce">
          Coming Soon At Your Place
        </p>
      </div>

      {/* Add a contained burst effect inside the container */}
      <div className="absolute inset-0 bg-gradient-to-r from-yellow-400 to-red-600 opacity-40  animate-blast"></div>

      {/* Add an animation wrapper for background change and explosion effect */}
      <style jsx>{`
        /* Keyframe for sliding and bouncing text animation */
        @keyframes textSlide {
          0% {
            transform: translateY(30px);
            opacity: 0;
          }
          50% {
            transform: translateY(-10px);
            opacity: 0.6;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }

        /* Apply the text sliding and bouncing effect */
        .animate-text-slide {
          animation: textSlide 1s ease-out forwards;
        }

        /* Keyframe for contained burst animation (only grows within the div) */
        @keyframes containedBurst {
          0% {
            transform: scale(1);
            opacity: 0.4;
          }
          50% {
            transform: scale(1.1);
            opacity: 0.6;
          }
          100% {
            transform: scale(1);
            opacity: 0.4;
          }
        }

        /* Apply the contained burst effect */
        .animate-blast {
          animation: containedBurst 1.5s ease-in-out infinite;
        }
      `}</style>
    </div>
  );
};

export default ComingSoon;
