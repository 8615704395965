import React from "react";
import fertilizer from "./assets/fertilizer.jpeg";
import cattlefeed from "./assets/cattlefeed.jpeg";
import pesticides from "./assets/pesticides.jpeg";
import seeds from "./assets/seeds.jpeg"

const Categories = () => {
    return (
        <>
            <h1 className="text-2xl font-bold text-green-600 text-center mb-4">Our Premium Products</h1>
            <div className="flex justify-center gap-6 p-4 overflow-x-auto">
                <div className="flex flex-col items-center max-w-[120px]">
                    <img 
                        src={fertilizer} 
                        alt="Fertilizer" 
                        className="w-24 h-24 rounded-full object-cover"
                    />
                    <p className="mt-2 text-center text-sm font-medium">Fertilizer</p>
                </div>
                <div className="flex flex-col items-center max-w-[120px]">
                    <img 
                        src={cattlefeed} 
                        alt="Cattle feeds" 
                        className="w-24 h-24 rounded-full object-cover"
                    />
                    <p className="mt-2 text-center text-sm font-medium">Cattle Feeds</p>
                </div>
                <div className="flex flex-col items-center max-w-[120px]">
                    <img 
                        src={pesticides} 
                        alt="Pesticides" 
                        className="w-24 h-24 rounded-full object-cover"
                    />
                    <p className="mt-2 text-center text-sm font-medium">Pesticides</p>
                </div>
                <div className="flex flex-col items-center max-w-[120px]">
                    <img 
                        src={seeds} 
                        alt="Seeds" 
                        className="w-24 h-24 rounded-full object-cover"
                    />
                    <p className="mt-2 text-center text-sm font-medium">Seeds</p>
                </div>
            </div>
        </>
    );
};

export default Categories;
