import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./slider.css";
import banner1 from "./assets/ban-1.jpg";
import banner2 from "./assets/homepage-banner1.jpg";
import banner3 from "./assets/ban-3.jpg"

const Slider = () => {
    return (
        <Carousel
            showThumbs={false}
            autoPlay
            infiniteLoop
            showStatus={false}
            interval={3000}
            transitionTime={500}
            swipeable
        >
            {/* First Slide */}
            <div>
                <img src={banner1} alt="First slide" />
                <div className="carousel-caption">
                    {/* <h2>First slide label</h2> */}
                    <p>Empowering Farmers with Quality Supplies</p>
                </div>
            </div>
            
            {/* Second Slide */}
            <div>
                <img src={banner2} alt="Second slide" />
                <div className="carousel-caption">
                    {/* <h2>Second slide label</h2> */}
                    <p>Launching Soon: Your Trusted Source for Quality Farming Products!</p>
                </div>
            </div>
            
            {/* Third Slide */}
            <div>
                <img src={banner3} alt="Third slide" />
                <div className="carousel-caption">
                    {/* <h2>Third slide label</h2> */}
                    <p>Sowing the Seeds of Success for Every Farmer</p>
                </div>
            </div>
        </Carousel>
    );
};

export default Slider;
